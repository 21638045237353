import React from 'react'

import ContentStyle from './content.module.css'

export const PeopleContent = ({content, position, title}) => (
  <div className="entry">
    <div className="entry-content">
      <div className={ContentStyle.content} dangerouslySetInnerHTML={{__html: content}}/>
    </div>
  </div>
)
