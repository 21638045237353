import React from 'react';
import {graphql} from 'gatsby';
import Layout from 'components/layout'
import {PeopleContent} from 'components/people/content'
import {Header} from 'components/people/header'

import Img from 'gatsby-image'
const SinglePeopleTemplate = ({data, ...props}) => (
  <Layout
    articleId={`${data.page.type}-${data.page.wordpress_id}`}
    articleClassName={`${data.page.classes}`}
    context = {props.pageContext}
    location = {props.location}
    ancestors= {data.page.ancestors}
  >
   <div className="entry">
    <div className="entry-content container px-5 container mt-16" >
      <div className="md:flex items-start ">
        {data.page.featured_media && data.page.featured_media.localFile && 
        <div style={{width: 375}} className="mb-10 md:mr-5 flex-shrink-0">
          <Img fluid={data.page.featured_media.localFile.childImageSharp.mobile} />
        </div>
        }
        <div className="flex-shrink">
          <Header {...data.page } position={data.page.acf.position}/>
          <PeopleContent {...data.page}/>
        </div>
      </div>
  </div>
   </div> 
  </Layout>
);

export default SinglePeopleTemplate

export const SinglePeopleQuery = graphql `
query SinglePeopleById($id: String!){
  page:wordpressWpPeople(id:{eq:$id}){
    title
    content
    path
    type
    date
    wordpress_id
    ancestors {
       label
       link
    }
    featured_media {
     localFile {
       ...PageBannerImages
     }
    }
acf {
position
}
    classes
  }
}
`

